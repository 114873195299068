<template>
    <page back>
        <el-form
            ref="addForm"
            size="small"
            :model="formModel"
            :rules="formRules"
        >
            <el-row>
                <el-col :span="12">
                    <el-form-item
                        prop="stationName"
                        label="站点名称"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.stationName"
                            placeholder="请输入站点名称"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="stationSn"
                        label="站点编号"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.stationSn"
                            placeholder="请输入站点编号"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="stationLogo"
                        label="站点LOGO"
                        label-width="12em"
                    >
                        <upload
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="10"
                            v-model="formModel.stationLogo"
                            tips=""
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item
                        prop="stationOpenTime"
                        label="站点营业时间"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.stationOpenTime"
                            placeholder="请输入站点营业时间"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="stationDesc"
                        label="站点描述"
                        label-width="12em"
                    >
                        <Tinymce
                            :height="300"
                            image-action="/rs/attachment/uploadFileLibrary"
                            v-model="formModel.stationDesc"
                            image-upload-tips=""
                        />
                    </el-form-item>
                    <el-form-item
                        prop="stationRegionId"
                        label="站点地区"
                        label-width="12em"
                    >
                        <CascaderPicker
                            clearable
                            filterable
                            :api-class="regionApi"
                            v-model="formModel.stationRegionId"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="stationDetailAddress"
                        label="站点详细地址"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.stationDetailAddress"
                            placeholder="请输入站点详细地址"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item
                        prop="LatLng"
                        label="站点地址坐标"
                        label-width="12em"
                    >
                        <LatLngPicker v-model="formModel.latLng "/>
                    </el-form-item>
                    <el-form-item
                        prop="contactFullname"
                        label="联系人姓名"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.contactFullname"
                            placeholder="请输入联系人姓名"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="contactTel"
                        label="联系人电话"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.contactTel"
                            placeholder="请输入联系人电话"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item
                        prop="isAvailable"
                        label="是否可用"
                        label-width="12em"
                    >
                        <el-switch
                            v-model="formModel.isAvailable"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="remarks"
                        label="备注"
                        label-width="12em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.remarks"
                            placeholder="请输入备注"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item
                        label-width="12em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm()"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { Utils } from 'vmf';

export default {
    name: 'StationEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                stationName: '',
                stationSn: '',
                stationLogo: [],
                stationOpenTime: '',
                stationDesc: '',
                stationRegionId: '',
                stationDetailAddress: '',
                latLng: '',
                contactFullname: '',
                contactTel: '',
                isAvailable: true,
                remarks: '',
            },
            // 表单校验规则
            formRules: {
                stationName: {
                    required: true,
                    message: '请输入',
                },
                stationSn: [
                    {
                        required: true,
                        message: '请输入',
                    },
                    {
                        validator: this.checkStationSn,
                        message: '站点编号不能重复',
                    },
                ],
                stationOpenTime: {
                    required: true,
                    message: '请输入',
                },
                contactFullname: {
                    required: true,
                    message: '请输入',
                },
                contactTel: {
                    required: true,
                    message: '请输入',
                },
                stationLogo: {
                    required: true,
                    message: '请输入',
                    type: 'array',
                },
            },
            regionApi: this.$api.Rs.Region,
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Pe.Station.save({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        initDetail() {
            this.$api.Pe.Station.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
            });
        },
        checkStationSn: Utils.debounce(function func(rule, value, callback) {
            this.$api.Pe.Station.checkStationSn({
                id: this.formModel.id,
                stationSn: this.formModel.stationSn,
            }).then(res => {
                if (res.data.data) {
                    callback(rule.message);
                } else {
                    callback();
                }
            });
        }, 500),
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.initDetail();
        }
    },
};
</script>

<style scoped lang="scss">
</style>
